<template>
    <div class="head-wrap">
        <div class="fixed_wrap" @click="getHelp"></div>
        <!-- pc 布局【顶部】语言选择 -->
        <div class="pc-head-box">
            <b-container class="pc-head-container">
                <b-row>
                    <b-col>{{$t('message.header')}}</b-col>
                    <b-col>
                        <div class="header-box" @click="languageFlag=!languageFlag">
                            <span v-show="languageName=='cn'"><img :src="base_url+languageCodeObj.national_flag" :alt="languageCodeObj&&languageCodeObj.cn_name"/></span>
                            <span v-show="languageName=='en'"><img :src="base_url+languageCodeObj.national_flag" :alt="languageCodeObj&&languageCodeObj.en_name"/></span>
                            <span v-show="languageName=='it'"><img :src="base_url+languageCodeObj.national_flag" :alt="languageCodeObj&&languageCodeObj.it_name"/></span>
                            <span class="t_icon"><span class="el-icon-arrow-right"></span></span>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <!-- wap布局【顶部】语言选择 -->
        <b-container class="wap-head-box">
            <b-row>{{$t('message.header')}}</b-row>
            <b-row>
                <div class="header-box" @click="languageFlag=!languageFlag">
                    <span v-show="languageName=='cn'"><img :src="base_url+languageCodeObj.national_flag" :alt="languageCodeObj&&languageCodeObj.cn_name"/></span>
                    <span v-show="languageName=='en'"><img :src="base_url+languageCodeObj.national_flag" :alt="languageCodeObj&&languageCodeObj.en_name"/></span>
                    <span v-show="languageName=='it'"><img :src="base_url+languageCodeObj.national_flag" :alt="languageCodeObj&&languageCodeObj.it_name"/></span>
                    <span class="t_icon"><span class="el-icon-arrow-right"></span></span>
                </div>
            </b-row>
        </b-container>
        <!-- pc和wap公用布局【banner2】吸烟有害健康 -->
        <div class="pc-nav-box">
            <b-container class="pc-nav-container">
                <b-row>
                    <div class="nav-group">
                        <div class="nav-group-box">
                            <span class="stopicon"></span>|<span class="txtspe" :class="languageName=='cn'?'cnstyles':''">{{$t('message.nav-head')}}</span></div>
                        </div>
                    <div class="nav-group-txt" :class="languageName=='cn'?'cnstyle':''">{{$t('message.nav')}}</div>
                </b-row>
            </b-container>
        </div>
        <!-- pc布局【nav】免邮文章和店铺定位 -->
        <div class="pc-nav-next-box">
            <b-container class="pc-container">
                <b-row>
                    <b-col><span class="common-style-left"><router-link class="lias" :to="{name:'article',query:{id:27}}"><span v-show="languageName=='cn'">{{amount}}</span> {{$t('message.navigazione')}}<span v-show="languageName!='cn'">{{amount}}</span><span class="el-icon-arrow-right"></span></router-link></span></b-col>
                    <b-col>
                        <div class="pc-nav-right">
                            <router-link class="lias" to="/map">{{$t('message.navigazionemap')}}<span class="el-icon-arrow-right"></span></router-link>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <!-- pc布局【nav-list】导航 -->
        <Affix>
            <div class="pc-nav-list-box ">
                <b-container class="pc-container-nav-group">
                    <div class="pc-nav-group-team">
                        <div class="nav-log"><router-link class="lias" to="/"><img src="@/assets/images/logo.png" alt=""></router-link></div>
                        <div class="nav-list">
                            <div class="nav-list-item" @mouseover="getMmouseUpNav" @mouseleave="getMmouseDown">{{$t('message.shop')}}</div>
                            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                                <el-menu-item index="2" :class="languageName=='it'?'pad5':''">
                                    <router-link class="lias" to="/home">{{$t('message.special')}}</router-link>
                                </el-menu-item>
                                <el-submenu index="3">
                                    <template slot="title" :class="languageName=='it'?'pad5':''">{{$t('message.aboutus')}}</template>
                                    <el-menu-item index="2-1"><router-link class="lias" :to="{name:'article',query:{id:28}}">{{$t('message.usoutjoins')}}</router-link></el-menu-item>
                                    <el-menu-item index="2-2"><router-link class="lias" :to="{name:'article',query:{id:29}}">{{$t('message.uspromise')}}</router-link></el-menu-item>
                                </el-submenu>
                                <el-submenu index="4">
                                    <template slot="title" :class="languageName=='it'?'pad5':''">{{$t('message.support')}}</template>
                                    <el-menu-item index="4-1"><router-link class="lias" to="/problem">{{$t('message.seeproblem')}}</router-link></el-menu-item>
                                    <el-menu-item index="4-2"><router-link class="lias" :to="{name:'article',query:{id:31}}">{{$t('message.linkus')}}</router-link></el-menu-item>
                                    <el-menu-item index="4-3"><router-link class="lias" :to="{name:'article',query:{id:23}}">{{$t('message.zzauto')}}</router-link></el-menu-item>
                                    <el-menu-item index="4-4"><router-link class="lias" :to="{name:'article',query:{id:24}}">{{$t('message.warrantybill')}}</router-link></el-menu-item>
                                    <el-menu-item index="4-5"><router-link class="lias" :to="{name:'article',query:{id:32}}">{{$t('message.usebook')}}</router-link></el-menu-item>
                                </el-submenu>
                                <!-- <el-menu-item index="5" :class="languageName=='it'?'pad5':''"><router-link class="lias" to="/cooperation">{{$t('message.cooperation')}}</router-link></el-menu-item> -->
                                <el-menu-item index="6" :class="languageName=='it'?'pad5':''"><router-link class="lias" to="/blog">{{$t('message.blog')}}</router-link></el-menu-item>
                            </el-menu>
                        </div>
                    </div>
                    <div class="pc-nav-car-user">
                        <div class="nav-info">
                            <Menu mode="horizontal" :theme="theme1" active-name="1" v-show="login_user_info&&login_user_info.token">
                                <Submenu name="1">
                                    <template slot="title">
                                        <span class="usericon"></span>
                                        <span class="user-name">{{login_user_info&&login_user_info.family_name}}{{login_user_info&&login_user_info.name}}</span>
                                    </template>
                                    <Menu-group title="">
                                        <Menu-item name="1-1"><router-link class="lias" to="/myself">{{$t('message.accountinfo')}}</router-link></Menu-item>
                                        <Menu-item name="1-2"><router-link class="lias" to="/myorder">{{$t('message.myorder')}}</router-link></Menu-item>
                                        <Menu-item name="1-3"><router-link class="lias" to="/myaddress">{{$t('message.addressfrom')}}</router-link></Menu-item>
                                        <Menu-item name="1-4"><span class="lias" @click="getOutLogin">{{$t('message.outlog')}}</span></Menu-item>
                                    </Menu-group>
                                </Submenu>
                            </Menu>
                        </div>
                        <span class="nav-icon" @click="getLoging" v-show="!login_user_info.token"><span class="usericon"></span></span>
                        <span class="nav-icon" @click="getShopCar"><span class="caricon"></span></span>
                    </div>
                </b-container>
                <!-- 导航商品 -->
                <transition-group name="test">
                    <div class="pc-shop-wrap" v-show="mouserflag" :key="1" @mouseover="getMmouseUp" @mouseleave="getMmouseDown">
                        <b-container class="pc-container-nav-wrap">
                            <b-row >
                                <b-col class="pc-container-nav-group-item" v-for="(item,key) in oneTypeArr" :key="key">
                                    <div class="pc-shop-title">
                                        {{languageName=='cn'?item.cn_title:''}}
                                        {{languageName=='en'?item.en_title:''}}
                                        {{languageName=='it'?item.it_title:''}}
                                    </div>
                                    <div class="pc-shop-txt" @click="getShopList(key)">
                                        {{$t('message.cashop')}}<span class="el-icon-arrow-right"></span>
                                    </div>
                                    <div class="pc-shop-group">
                                        <div class="pc-shop-group-item" @click="getShopInfo(item,v,key)" v-for="(v,index) in item.child" :key="index">
                                            <div class="pc-shop-img">
                                                <img v-show="v.goods_img!=''" :src="v.goods_img" alt="">
                                            </div>
                                            <div class="pc-shop-name" v-show="languageName=='cn'">{{v.cn_name}}</div>
                                            <div class="pc-shop-name" v-show="languageName=='en'">{{v.en_name}}</div>
                                            <div class="pc-shop-name" v-show="languageName=='it'">{{v.it_name}}</div>
                                        </div>
                                    </div>
                                    <div class="pc-bor-center"></div>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                </transition-group>
            </div>
        </Affix>
        <!-- wap布局【nav】免邮文章和店铺定位 -->
        <b-container class="wap-nav-box">
            <b-row>
                <b-col><span class="common-style-left"><router-link class="lias" :to="{name:'article',query:{id:27}}">{{$t('message.navigazione')}}<span class="el-icon-arrow-right"></span></router-link></span></b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="wap-nav-right">
                        <router-link class="lias" to="/map">{{$t('message.navigazionemap')}}<span class="el-icon-arrow-right"></span></router-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <!-- wap布局【nav-list】导航 -->
        <div class="wap-nav-list-box">
            <b-container class="wap-container-nav-list">
                <b-row>
                    <b-col class="lef"><span class="nav-btn" @click="getWapMenu"><b-icon-list></b-icon-list></span></b-col>
                    <b-col class="centr"><div class="nav-log"><router-link class="lias" to="/"><img src="@/assets/images/logo.png" alt=""></router-link></div></b-col>
                    <b-col class="leg">
                        <span class="nav-icon" @click="getLoging('mobile')"><span class="usericon"></span>{{login_user_info&&login_user_info.name}}</span>
                        <span class="nav-icon" @click="getShopCar"><span class="caricon"></span></span>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <!-- pc和wap公用布局弹窗【切换语言弹窗】 -->
        <b-modal size="sm" centered v-model="languageFlag" hide-footer :title="$t('message.chioselang')">
            <div class="language-item" v-for="(item,index) in languageList" :key="index" @click="getLanguage(item)">
                <span class="nationalFlag"><img :src="base_url+item.national_flag" :alt="item.national_flag"></span>
                {{languageName=='cn'?item.cn_name:''}}
                {{languageName=='en'?item.en_name:''}}
                {{languageName=='it'?item.it_name:''}}
                <span v-show="languageCodeObj&&languageCodeObj.id==item.id" class="iconCheckFlag">
                    <span class="el-icon-check"></span>
                </span>
            </div>
        </b-modal>
        <!-- wap【菜单】 -->
        <van-popup class="wap-menu" v-model="show" position="top" :style="{ height: '90%' }" >
            <div class="wap-menu-head">
                <b-row>
                    <b-col class="wap-menu-lef"><span class="nav-btn" @click="getWapMenu">&times;</span></b-col>
                    <b-col class="wap-menu-centr"><div class="nav-log"><router-link class="lias" to="/"><img src="@/assets/images/logo.png" alt=""></router-link></div></b-col>
                    <b-col class="wap-menu-leg">
                        <span class="nav-icon" @click="getLoging('mobile')"><span class="usericon"></span>{{login_user_info&&login_user_info.name}}</span>
                        <span class="nav-icon" @click="getShopCar"><span class="caricon"></span></span>
                    </b-col>
                </b-row>
            </div>
            <div class="wap-menu-list">
                <div class="wap-menu-list-item" v-for="(item,index) in menuList" :key="index">
                    <div class="wap-menu-list-item-title" @click.stop="getTabMenu(item)">
                        <div class="tit">
                            {{languageName=='cn'?item.cn_name:''}}
                            {{languageName=='en'?item.en_name:''}}
                            {{languageName=='it'?item.it_name:''}}  
                        </div>
                        <span class="el-icon-arrow-down"></span>
                    </div>
                    <div class="wap-menu-list-item-group" v-if="index==0">
                        <div class="wap-menu-list-item-group-item" v-show="item.falg" v-for="(v,k) in oneTypeArr" :key="k">
                            <div class="wap-menu-list-item-group-tit" @click.stop="getTabMenuItem(v)">
                                <div class="tit">
                                {{languageName=='cn'?v.cn_title:''}}
                                {{languageName=='en'?v.en_title:''}}
                                {{languageName=='it'?v.it_title:''}}
                                </div>
                                <span class="el-icon-arrow-down"></span>
                            </div>
                            <div class="wap-menu-list-item-group-list">
                                <div class="wap-menu-group-list-item" @click.stop="getTabMenuItemChild(i)" v-for="(i,key) in v.child" :key="key">
                                    <div class="wap-menu-list-img" v-show="i.goods_img!=''"><img :src="i.goods_img" alt=""></div>
                                    <div class="wap-menu-list-txt">
                                        {{languageName=='cn'?i.cn_name:''}}
                                        {{languageName=='en'?i.en_name:''}}
                                        {{languageName=='it'?i.it_name:''}}
                                    </div>
                                </div>
                                <div class="wap-menu-more" @click="getMore(k)">{{$t('message.cashop')}}<span class="el-icon-arrow-right"></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="wap-menu-list-item-group" v-else>
                        <div class="wap-menu-list-item-group-item" v-show="item.falg" v-for="(v,k) in item.list" :key="k">
                            <div class="wap-menu-list-item-group-tit" @click.stop="getTabMenuItem(v)">
                                <div class="tit">
                                    {{languageName=='cn'?v.cn_name:''}}
                                    {{languageName=='en'?v.en_name:''}}
                                    {{languageName=='it'?v.it_name:''}}
                                </div>
                                <span class="el-icon-arrow-down"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {yooz_url,yooz_lang,setLocalItem,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'',
    inject:['reload'],
    data() {
        return {
            amount:'',
            languageName:'',
            base_url:yooz_url,
            login_user_info:{},
            show:false,
            mouserflag:false,
            theme1: 'light',
            // 多国语言切换
            languageCodeObj:{},
            languageList:[],
            languageFlag:false,
            activeIndex: '1',
            shopdata:[
                {
                    title:'一级分类名称占位1',
                    child:[
                        {
                            url:'//cdn.shopify.com/s/files/1/0556/3636/1408/products/TangyPurple2_400x.jpg?v=1652429090',
                            name:'RELX INFINITY设备',
                        },{
                            url:'//cdn.shopify.com/s/files/1/0556/3636/1408/products/RaspyRuby2_400x.jpg?v=1652423806',
                            name:'悦刻 Infinity 设备',
                        },{
                            url:'//cdn.shopify.com/s/files/1/0556/3636/1408/products/DarkSparkle2_400x.jpg?v=1652423806',
                            name:'RELX PRO PDO',
                        }
                    ]
                },{
                    title:'一级分类名称占位2',
                    child:[
                        {
                            url:'//cdn.shopify.com/s/files/1/0556/3636/1408/products/CC_small_1_400x.png?v=1625656455',
                            name:'无线充电盒',
                        }
                    ]
                }
            ],
            oneTypeArr:[],
            navShopList:[],
            menuList:[
                {
                    cn_name:'商品',
                    en_name:'Store',
                    it_name:'Acquista',
                    falg:false,
                    list:[
                        {
                            name:'一级分类名称占位1',
                            falg:false,
                            list:[
                                {name:'RELX INFINITY 设备',url:require("@/assets/images/pro/pro3.png"),path:'info'},
                                {name:'基础设备',url:require("@/assets/images/pro/pro12.png"),path:'info'},
                                {name:'RELX Pod Pro',url:require("@/assets/images/pro/pro7.png"),path:'info'},
                            ]
                        },{
                            name:'二级分类名称占位2',
                            falg:false,
                            list:[
                                {name:'基础设备',url:require("@/assets/images/pro/pro17.png"),path:'info'},
                            ]
                        }
                    ]
                },{
                    cn_name:'特别优惠',
                    en_name:'Special offers',
                    it_name:'Offerte Speciali',
                    path:'home'
                },{
                    cn_name:'关于我们',
                    en_name:'About us',
                    it_name:'Chi siamo',
                    falg:false,
                    list:[
                        {
                            cn_name:'我们的外部参与',
                            en_name:'Our external engagement',
                            it_name:'Il nostro impegno esterno',
                            path:'article',
                            id:28,
                        },{
                            cn_name:'我们的承诺',
                            en_name:'our commitment',
                            it_name:'il nostro impegno',
                            path:'article',
                            id:29,
                        }
                    ]
                },{
                    cn_name:'支持',
                    en_name:'Support',
                    it_name:'Supporto',
                    falg:false,
                    list:[
                        {
                            cn_name:'常见问题',
                            en_name:'Commonly asked questions',
                            it_name:'Domande frequenti',
                            path:'problem'
                        },{
                            cn_name:'联系我们',
                            en_name:'Contact us',
                            it_name:'Contatti',
                            path:'article',
                            id:31,
                        },{
                            cn_name:'资质认证',
                            en_name:'Certification',
                            it_name:'Certificazione',
                            path:'article',
                            id:23,
                        },{
                            cn_name:'保修单',
                            en_name:'Insurance',
                            it_name:'Garanzia',
                            path:'article',
                            id:24,
                        },{
                            cn_name:'用户手册',
                            en_name:'User Manual',
                            it_name:"Manuale d'uso",
                            path:'article',
                            id:32,
                        }
                    ]
                },
                // {
                //     cn_name:'合作机会',
                //     en_name:'Partnership opportunities',
                //     it_name:"Opportunita' di partnership",
                //     path:'cooperation'
                // },
                {
                    cn_name:'博客',
                    en_name:"Follow us on",
                    it_name:"Seguici sui social",
                    path:'blog'
                }
            ],
            height:'',
            userPople:''//userPople:'company' 企业用户，数量10+
        }
    },
    computed:{
        
    },
    watch:{
       
    },
    methods: {
        // 获取国家包邮金额范围
        getPackAmount(){
            if(this.languageName){
                this.$http.api_order_getpackagemailamount({
                    country:this.languageName//国家编码
                }).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.amount = res.data.amount;
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        },
        // wap一级菜单
        getTabMenu(item){
            if(item.list){
                item.falg = !item.falg;
            }else{
                this.show = false;
                this.$router.push({path:'/'+item.path});
            }
        },
        // wap二级菜单
        getTabMenuItem(item){
            if(item.path){
                this.show = false;
                if(item.id){
                    this.$router.push({path:'/'+item.path,query:{id:item.id}});
                }else{
                    this.$router.push({path:'/'+item.path});
                }
            }else{
                item.falg = !item.falg;
            }
        },
        // wap三级菜单
        getTabMenuItemChild(item){
            this.show = false;
            if(item.id){
                this.$router.push({path:'/info',query:{shopid:item.id}});
            }
        },
        // wap菜单按钮
        getWapMenu(){
            this.show = !this.show;
        },
        // pc 商品导航
        getMmouseUp(){
            if(this.height=='1'){
                this.mouserflag = false;
            }else{
                this.mouserflag = true
            }
        },
        getMmouseUpNav(){
            this.height='';
            this.mouserflag = true
        },
        // pc 商品导航
        getMmouseDown(){
            this.mouserflag = false;
        },
        // wap 查看全部商品
        getMore(index){
            this.reload();
            this.show = false;
            this.$router.push({path:'/list',query:{type:index}});
        },
        // pc 查看全部商品
        getShopList(index){
            this.height = '1';
            this.reload();
            this.$router.push({path:'/list',query:{type:index}})
            this.mouserflag = false;
        },
        // pc商品点击进详情
        getShopInfo(item,v,key){
            this.height = '1';
            this.reload();
            this.mouserflag = false;
            this.$router.push({path:'/info',query:{shopid:v.id,shopName:item.title,type:key}})
        },
        // 语言选择
        getLanguage(item){
            this.languageName = item.key;
            this.languageCodeObj = item;
            this.$i18n.locale = item.key;
            localStorage.lang = item.key;
            localStorage.langcode = item.lang;
            this.languageFlag = !this.languageFlag;
            this.$root.Bus.$emit('languageClick', item);
            this.$store.commit('setLanguageNameStatus', item);
            setLocalItem('getLanguageObject',item);
            this.reload();
        },
        // 问客服
        getHelp(){
            this.$store.commit('setHelpStatus',true);
        },
        // pc和wap个人账户按钮
        getLoging(type){
            if(type=='mobile'){
                if(this.login_user_info.token){
                    this.$router.push({path:'/users'});
                }else{
                    this.$root.Bus.$emit('languageClick', this.languageCodeObj);
                    this.$store.commit('setLanguageNameStatus', this.languageCodeObj);
                    this.$store.commit('setLanguageNameList', this.languageList);
                    this.$store.commit('setLoginStatus',true);
                }
            }else{
                this.$root.Bus.$emit('languageClick', this.languageCodeObj);
                this.$store.commit('setLanguageNameStatus', this.languageCodeObj);
                this.$store.commit('setLoginStatus',true);
            }
        },
        // pc和wap购物车按钮
        getShopCar(){
            if(this.login_user_info.token){
                this.$router.push({path:'/shopcar'});
            }else{
                this.$store.commit('setLoginStatus',true);
            }
        },
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
        },
        // 退出登录
        getOutLogin(){
            this.reload();
            this.login_user_info = {};
            this.$store.commit('setLoginNameStatus', {});
            delLocalItem('login_user_info');
        },
        //pc和wap导航商品一级数据
        getNavShopList(){
            //商品导航数据【顶级分类和首页推荐商品数据】
            this.$http.api_goods_levelone().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.oneTypeArr = res.data;
                            res.data.map((v)=>{
                                this.getNavChindList(v);
                            });
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        //pc和wap导航商品二级数据
        getNavChindList(v){
            this.$http.api_goods_shoplist({
                typeid:v.id,//typeid（顶级类型）、
                typetwoid:'',//（二级类型）、
                ishome:1,//（是否首页推荐）、
                page:1,//（页数）、
                size:0,//（每页条数默认10条值为0无分页）
                iscompany:this.userPople=='company'?1:0,//（是否为企业身份 1是0否）
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data&&res.data.length>0){
                            res.data.map((i)=>{
                                i.typeid = v.id;
                            });
                            let params = [];
                            this.oneTypeArr.map((item)=>{
                                if(item.id==v.id){
                                    if(v.id==res.data[0].typeid){
                                        params = res.data;
                                    }
                                    item.child = params;
                                }
                            });
                        }
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        return false;
                    }
                }
            });
        },
        // 获取多国家数据-语言弹窗展示数据
        getLanguageList(){
            this.$http.api_datalist_countrylist().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.languageList = res.data;
                            this.$store.commit('setLanguageNameList', res.data);
                            setLocalItem('getLanguageListName',res.data);
                            // 根据缓存监测当前选中的语言项
                            if(localStorage.lang&&localStorage.lang!='undefined'){
                                res.data.map((item)=>{
                                    if(item.key == localStorage.lang){
                                        this.languageName = localStorage.lang;
                                        this.languageCodeObj = item;
                                        this.$store.commit('setLanguageNameStatus', item);
                                    }
                                });
                            }else{
                                res.data.map((v)=>{
                                    if(v.key == yooz_lang){
                                        this.languageName = v.key;
                                        this.languageCodeObj = v;
                                        this.$root.Bus.$emit('languageClick', v);
                                        this.$store.commit('setLanguageNameStatus', v);
                                    }
                                })
                            }
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        }
    },
    created(){
        let that = this;
        that.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        // 缓存登录用户信息
        let login_user_info = that.$store.state.data.loginNameStatus.family_name?that.$store.state.data.loginNameStatus:(getLocalItem('login_user_info')?getLocalItem('login_user_info'):null);;
        if(login_user_info){
            that.login_user_info = login_user_info;
            // 再次判断是否是企业用户(是否企业身份 1是 0否)
            if(login_user_info.is_company==1){
                this.userPople='company';
            }
        }
        that.getLanguageList();//多语言：获取国家列表数据
        that.getNavShopList();//导航商品列表数据
        this.getPackAmount();
        that.$root.Bus.$on('userInfoClick', function (msg) {
            that.login_user_info = msg;
            that.reload();
        });
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.fixed_wrap{
    position: fixed;
    right: 5%;
    top:80%;
    z-index:100;
    width:80px;
    height:80px;
    border-radius:50%;
    background: url(~@/assets/images/icon_kefu.png) no-repeat left top;
    background-size: 100% 100%;
    cursor: pointer;
}
.wap-menu{
    .wap-menu-head{
        width: 100%;
        height:80px;
        line-height:80px;
        .row{padding:0;margin:0;}
        .wap-menu-lef{
            text-align: left;
            .nav-btn{
                display: inline-block;
                width: 30px;
                font-size:40px;
            }
        }
        .wap-menu-centr{
            width: 40%;
            text-align: center;
            .nav-log{
                .lias{
                    display: block;
                    img{
                        height:80px;
                        width: 80%;
                    }
                }
                
            }
        }
        .wap-menu-leg{
            height:50px;
            display: flex;
            justify-content: flex-end;
            text-align: right;
            vertical-align: middle;
            .nav-icon{
                cursor: pointer;
                display: inline-block;
                height:30px;
                margin:0 5px 0;
                vertical-align: middle;
                
                .caricon{
                    display: inline-block;
                    height:30px;
                    width: 30px;
                    background: url(~@/assets/images/icon_shopcar.png) no-repeat left top;
                    background-size:100% 100%;
                    vertical-align: middle;
                }
                .usericon{
                    display: inline-block;
                    height:30px;
                    width: 30px;
                    vertical-align: middle;
                    background: url(~@/assets/images/icon_user.png) no-repeat left top;
                    background-size:100% 100%;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .wap-menu-list{
        width: 100%;
        padding:10px;
        .wap-menu-list-item{
            width: 100%;
            .wap-menu-list-item-title{
                font-size:16px;
                font-weight: bold;
                line-height: 45px;
                border-bottom: 1px solid #DDE0ED;
                .tit{
                    display: inline-block;
                    text-align: left;
                    width: 90%;
                }
            }
            .wap-menu-list-item-group{
                padding:0px 0;
                .wap-menu-list-item-group-item{
                    .wap-menu-list-item-group-tit{
                        font-size:16px;
                        line-height: 45px;
                        .tit{
                            display: inline-block;
                            text-align: left;
                            width: 90%;
                            padding-left: 20px;
                        }
                    }
                    .wap-menu-list-item-group-list{
                        padding:0px 0 10px;
                        .wap-menu-group-list-item{
                            display: flex;
                            -webkit-box-orient: horizontal;
                            width: 100%;
                            padding:10px 10px 0;
                            .wap-menu-list-img{
                                width:25%;
                                height:80px;
                                img{
                                    width: 100%;
                                    height:80px;
                                }
                            }
                            .wap-menu-list-txt{
                                width:70%;
                                display: flex;
                                align-items: center;
                                padding-left:20px;
                            }
                        }
                        .wap-menu-more{
                            color:#1f73b7;
                            padding:10px;
                            
                            .el-icon-arrow-right{
                                color:#1f73b7;
                            }
                            .lias{
                                color:#1f73b7;
                                
                                text-decoration: none;
                                .el-icon-arrow-right{
                                    color:#1f73b7;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
}
.lias:hover{
    text-decoration: none;
    color:#2E2E2E;
}
.pc-head-box{
    width: 100%;
    background:#434343;
}
.pc-head-container{
    padding:20px 0;
    color: #ffffff;
    background: #434343;
}
.header-box{
    cursor: pointer;
    display: flex;
    -webkit-box-orient: horizontal;
    width:100%;
    height:40px;
    line-height:40px;
    border-radius:20px;
    
    padding-left:15px;
    background: #565656;
    position: relative;
    color: #ffffff;
    span{
        color: #ffffff;
        img{
            width:30px;
            height:20px;
        }
    }
    .t_icon{
        display: inline-block;
        position: absolute;
        right: 0;
        top:0;
        height:100%;
        width:40px;
        text-align: center;
        line-height:40px;
        vertical-align: middle;
        .el-icon-arrow-right{
            font-size:20px;
            margin-top: 9px;
            color: #ffffff;
        }
    }
}
.pc-nav-box{
    padding:10px 0;
    width:100%;
    background:#2E2E2E;
}
.wap-head-box{
    padding:10px 30px;
    color: #ffffff;
    background: #434343;
    .row{
        margin: 0 0 10px;
        
        color: #ffffff;
    }
}
.pc-nav-container{
    width: 100%;
    padding:0;
    .row{
        width: 100%;
        display: -webkit-flex;
        -webkit-align-items: center; 
        display: flex;
        align-items: center;
        padding:10px 0;
        margin: 0;
        img{
            height:110px;
            width:390px;
            margin: 0 auto;
        }
        .nav-group{
            width: 100%;
            display: -webkit-flex;
            -webkit-align-items: center; 
            display: flex;
            align-items: center;
            padding:0 10px;
            .nav-group-box{
                display: inline-block;
                margin: 0 auto;
                padding:5px 20px;
                text-align: center;
                border:1px solid #ffffff;
                
                color: #ffffff;
                .stopicon{
                    display: inline-block;
                    
                    height: 20px;
                    width:20px;
                    background: url(~@/assets/images/stop_img.png) no-repeat left top;
                    background-size: 100% 100%;
                    margin-right: 15px;
                    vertical-align: middle;
                    color: #ffffff;
                }
                .txtspe{
                    display: inline-block;
                    
                    margin-left:15px;
                    color : #ffffff;
                }
                .cnstyles{
                    letter-spacing:8px;
                }
            }
        }
        .nav-group-txt{
            width: 100%;
            
            color: #ffffff;
            text-align: center;
            padding:10px 0 0;
        }
        .cnstyle{
            letter-spacing:30px;
            padding-left:20px;
        }
    }
    
}

.language-item{
    padding:10px 0;
    
    position: relative;
    cursor: pointer;
    .nationalFlag{
        display: inline-block;
        width:30px;
        height:20px;
        vertical-align: middle;
        margin-top: -4px;
        margin-right: 8px;
        img{
            width:30px;
            height:20px;
        }
    }
    .italy{
        background:url(~@/assets/images/icon_italy.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .uk{
        background:url(~@/assets/images/icon_uk.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .china{
        background:url(~@/assets/images/icon_china.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .iconCheckFlag{
        position: absolute;
        right:0;
        top:10px;
        display: inline-block;
        color: #1f73b7;
        text-align: right;
        .el-icon-check{
            font-size:20px;
            font-weight: bold;
            color: #1f73b7;
        }
    }
}

.pc-nav-next-box{
    width: 100%;
    background: #f5f5f5;
}
.pc-container{
    padding:10px 0;
    
}

.test-enter,.test-leave-to{
    opacity: 0;
}
.test-enter-to,.test-leave{
    opacity: 1;
}
.test-enter-active,.test-leave-active{
    transition: all 1.3s;
}
.pc-nav-list-box{
    background: #ffffff;  
    width:100%;
    z-index:999;
    position: relative;
    .pc-container-nav-group{ 
        display: flex;
        -webkit-box-orient: horizontal;
        padding:0;
        .nav-log{
            padding:0;
            width:70px;
            line-height:55px;
            margin-right: 30px;
            .lias{
                display: block;
                img{
                    width: 100%;
                }
            }
            
        }
        .pc-nav-group-team{
            width: 75%;
            display: flex;
            -webkit-box-orient: horizontal;
            .nav-list{
                display: flex;
                -webkit-box-orient: horizontal;
                .nav-list-item{
                    cursor: pointer;
                    line-height:62px;
                    padding:0 16px;
                    font-size: $size16;
                }
            }
        }
        .pc-nav-car-user{
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            vertical-align: middle;
            .nav-info{
                cursor: pointer;
                display: inline-block;
                vertical-align: middle;
                .usericon{
                    display: inline-block;
                    height:30px;
                    width: 30px;
                    vertical-align: middle;
                    margin-right: 5px;
                    background: url(~@/assets/images/icon_user.png) no-repeat left top;
                    background-size:100% 100%;
                }
                .user-name{
                    display: inline-block;
                    max-width:80%;
                    height:60px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;   
                    vertical-align: middle;
                }
            }
            .nav-icon{
                cursor: pointer;
                display: inline-block;
                height:30px;
                line-height:30px;
                margin:0 15px 0;
                vertical-align: middle;
                .caricon{
                    display: inline-block;
                    height:30px;
                    width: 30px;
                    background: url(~@/assets/images/icon_shopcar.png) no-repeat left top;
                    background-size:100% 100%;
                    vertical-align: middle;
                    margin-right: 5px;
                }
                .usericon{
                    display: inline-block;
                    height:30px;
                    width: 30px;
                    vertical-align: middle;
                    background: url(~@/assets/images/icon_user.png) no-repeat left top;
                    background-size:100% 100%;
                }
                .el-icon-user-solid{
                    font-size:24px;
                    vertical-align: middle;
                }
                .el-icon-shopping-cart-full{
                    font-size:24px;
                    vertical-align: middle;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .pc-shop-wrap{
        position: absolute;
        left: 0;
        top:60px;
        width: 100%;
        height:auto;
        background: #ffffff;
        .pc-container-nav-wrap{
            background: #ffffff;  
            padding:30px 0px;
            .pc-container-nav-group-item{
                position: relative;
                padding:0 20px;
                &:last-child{
                    .pc-bor-center{display: none;}
                }
                .pc-bor-center{
                    position: absolute;
                    right:0;
                    top:0;
                    height:90%;
                    width:1px;
                    background: #dde0ed;
                }
                .pc-shop-title{
                    font-size:20px;
                    line-height: 40px;
                }
                .pc-shop-txt{
                    color: #1f73b7;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #1f73b7;
                        .el-icon-arrow-right{
                            color: #1f73b7;
                        }
                    }
                    .el-icon-arrow-right{
                        color: #1f73b7;
                    }
                }
                .pc-shop-group{
                    width: 100%;
                    clear: both;
                    .pc-shop-group-item{
                        width: 48%;
                        float: left;
                        padding:10px 0;
                        margin-right:4%;
                        min-height:140px;
                        cursor: pointer;
                        &:nth-child(even){
                            margin-right:0px;
                            width: 48%;
                        }
                        .pc-shop-img{
                            width: 100%;
                            min-height:100px;
                            text-align: center;
                            img{
                                display: inline-block;
                                width: 100%;
                                height:100%;
                                margin: 0 auto;
                            }
                        }
                        .pc-shop-name{
                            width: 100%;
                            height:40px;
                            line-height:20px;
                            overflow: hidden;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
.common-style-left{
    color:#616166;
    cursor: pointer;
    a{
        color:#616166;
    }
    a:hover{
        color:#616166;
    }
    .el-icon-arrow-right{
        color:#616166;
    }
}
.pc-nav-right{
    color: #3782be;
    text-align: right;
    cursor: pointer;
    a{
        color: #3782be;
        text-decoration: none;
        .el-icon-arrow-right{
            color: #3782be;
        }
        &:hover{
            color: #3782be;
        }
    }
}

/deep/.modal-content{
    min-width: 400px;
}
/deep/.bg-info{
    background: #ffffff !important;  
}
/deep/.navbar-dark .navbar-nav .nav-link{
    color: #2E2E2E !important;  
}
/deep/.navbar-dark .navbar-brand{
    color: #2E2E2E !important;  
}
/deep/.navbar-dark .navbar-toggler{
    color:  #2E2E2E !important;  
    border-color: #2E2E2E !important;  
}
// 模态窗样式调整设计图模样
/deep/.modal-content{
    border-radius:20px;
}
/deep/.modal-content{
    padding:0 25px;
}
/deep/.modal-header{
    padding-top:25px;
    padding-bottom:20px;
    padding-left:0px;
    padding-right:0px;
    .close{
        font-size: 28px;
        font-weight: normal;
    }
}
/deep/.modal-body{
    padding-left:0px;
    padding-right:0px;
}
/deep/.el-menu--horizontal>.el-menu-item{
    height: auto;
    // line-height: 62px;
    font-size:16px;
    a{font-size:16px;}
}
/deep/a{
    color:#2E2E2E ;
}
/deep/.el-menu--popup{
    border-radius:10px;
}
/deep/.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
    background: transparent;
    height:40px;
    line-height:40px;
}
/deep/.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow{
    display: none;
}
/deep/.el-menu--horizontal>.el-submenu .el-submenu__title{
    color: #2E2E2E;
    font-size:16px;
    height: 62px;
    line-height: 62px;
}
/deep/.el-menu--horizontal>.el-menu-item{
    color: #2E2E2E;
    
}
/deep/.el-menu.el-menu--horizontal{
    border-bottom: none;
}
/deep/.el-menu--horizontal>.el-menu-item.is-active{
    font-size:16px;
    border-bottom: 1px solid transparent;
    color: #2E2E2E;
}
.ivu-menu-horizontal.ivu-menu-light:after{
    background: transparent;
}
.el-icon-user-solid{
    font-size:30px;
}
/deep/.ivu-icon-ios-arrow-down:before{
    content: "";
}
.ivu-menu-horizontal .ivu-menu-item, .ivu-menu-horizontal .ivu-menu-submenu{
    padding:0;
}
.ivu-menu-submenu-title span>i, .ivu-menu-submenu-title>i{
    margin-right: 0;
}
/deep/.ivu-menu-submenu-title{
    height: 62px;
    line-height: 62px;
}
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item, .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu{
    color: #2E2E2E;
}
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active, .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover, .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active, .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover{
    color: #2E2E2E;
    border-bottom: 2px solid transparent;
}
.ivu-menu-light{
    background: transparent;
}
/deep/.ivu-menu-item-group-title{
    height:0px;
}
/deep/.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom:none;
}
/deep/.el-menu--horizontal > .el-submenu .el-submenu__title{
    line-height:63px;
    font-size:16px;
    
}

.pad5{
    padding:0 4px !important;
}
@media screen and (min-width: 1200px) {
    .head-wrap{
        .pc-head-container{
            .row{
                .col{
                    
                    color: #ffffff;
                }
            }
            .header-box{
                width:70%;
                float:right;
                color: #ffffff;
            }
        }
        .wap-head-box{display: none;}
        .wap-nav-box{display: none;}
        .wap-nav-list-box{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .head-wrap{
        .pc-head-container{
            .row{
                .col{
                    
                    color: #ffffff;
                }
            }
            .header-box{
                width:80%;
                float:right;
                color: #ffffff;
            }
        }
        .wap-head-box{display: none;}
        .wap-nav-box{display: none;}
        .pc-nav-list-box {
            .pc-container-nav-group{
                .pc-nav-car-user{
                    .nav-info{
                        max-width:70%;
                        .user-name{
                            max-width:50%;
                        }
                    }
                }
                .pc-nav-group-team{
                    .nav-list{
                        .nav-list-item{
                            padding:0 5px;
                        }
                    }
                }
                /deep/.el-submenu .el-submenu__title{
                    padding:0 5px;
                }
                /deep/.el-menu-item{
                    padding:0 5px;
                }
                
            }
        }
        .wap-nav-list-box{display: none;}
        
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .head-wrap{
        .pc-head-container{
            .row{
                .col{
                    
                    color: #ffffff;
                }
            }
            .header-box{
                width:80%;
                float:right;
                color: #ffffff;
            }
        }
        .pc-nav-box{
            padding: 10px 0 !important;
        }
        .pc-nav-list-box {
            .pc-container-nav-group{
                .pc-nav-car-user{
                    .nav-info{
                        max-width:70%;
                        .user-name{
                            max-width:30%;
                        }
                    }
                }
                .pc-nav-group-team{
                    .nav-list{
                        .nav-list-item{
                            padding:0 5px;
                        }
                    }
                }
                /deep/.el-submenu .el-submenu__title{
                    padding:0 5px;
                }
                /deep/.el-menu-item{
                    padding:0 5px;
                }
                
            }
        }
        .wap-head-box{display: none;}
        .wap-nav-box{display: none;}
        .wap-nav-list-box{display: none;}
        
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .head-wrap{
        .pc-head-container{
            .row{
                .col{
                    
                    color: #ffffff;
                }
            }
            .header-box{
                width:90%;
                float:right;
                color: #ffffff;
            }
        }
        .wap-head-box{display: none;}
        .wap-nav-box{display: none;}
        .pc-nav-list-box{display: none;}
        .wap-nav-list-box{
            width: 100%;
            background: #ffffff;
            .wap-container-nav-list{
                padding:10px 0;
                background: #ffffff;
                .row{
                    margin: 0;
                    .col{
                        text-align: right;
                        
                        .nav-btn{
                            display: inline-block;
                            margin:0 10px;
                            padding:3px;
                            border-radius:4px;
                            // border: 1px solid #aaaaaa;
                            .b-icon.bi{
                                font-size: 30px;
                            }
                        }
                        .nav-log{
                            padding:0;
                        }
                        .nav-icon{
                            display: inline-block;
                            margin:2px 10px 0 0;
                            color: #aaaaaa;
                            .el-icon-user-solid{
                                font-size:24px;
                            }
                            .el-icon-shopping-cart-full{
                                font-size:24px;
                            }
                        }
                    }
                    .lef{
                        text-align: left;
                        height: 60px;
                        line-height: 60px;
                    }
                    .centr{
                        text-align: center;
                        width: 40%;
                        text-align: center;
                        .nav-log{
                            .lias{
                                display: block;
                                img{
                                    width: 50%;
                                }
                            }
                            
                        }
                    }
                    .leg{
                        display: flex;
                        justify-content: flex-end;
                        text-align: right;
                        padding:0 10px 0 0;
                        .nav-icon{
                            cursor: pointer;
                            display: inline-block;
                            height:30px;
                            line-height:60px;
                            margin:0 5px 0;
                            vertical-align: middle;
                            
                            .caricon{
                                display: inline-block;
                                height:30px;
                                width: 30px;
                                background: url(~@/assets/images/icon_shopcar.png) no-repeat left top;
                                background-size:100% 100%;
                                vertical-align: middle;
                            }
                            .usericon{
                                display: inline-block;
                                height:30px;
                                width: 30px;
                                vertical-align: middle;
                                background: url(~@/assets/images/icon_user.png) no-repeat left top;
                                background-size:100% 100%;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
                
            }
        }
        .pc-nav-container{
            .row{
                img{
                    height:90px;
                    margin: 0 auto;
                }
                .nav-group{
                    padding:0 5px;
                    .nav-group-box{
                        font-size:12px;
                        padding: 5px ;
                        .stopicon{
                            font-size:12px;
                            height: 20px;
                            width:20px;
                            margin-right:5px;
                        }
                        .txtspe{
                            font-size:12px;
                            margin-left:5px;
                        }
                    }
                }
                .nav-group-txt{
                    font-size:12px;
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .head-wrap{
        .pc-head-box{display: none;}
        .wap-head-box{
            padding:10px 30px;
            color: #ffffff;
            background: #434343;
            .row{
                margin: 0 0 10px;
                
            }
        }
        .pc-nav-box{
            padding: 10px 0 !important;
        }
        .pc-nav-next-box{display: none;}
        .wap-nav-box{
            padding-top:5px;
            background: #f5f5f5;
            .wap-nav-right{
                color: #3782be;
                padding:5px 0;
                a{
                    color: #3782be;
                    .el-icon-arrow-right{
                        color: #3782be;
                    }
                }
            }
        }
        .pc-nav-list-box{display: none;}
        .wap-nav-list-box{
            width:100%;
            background: #ffffff;
            .wap-container-nav-list{
                padding:10px 0;
                background: #ffffff;
                .row{
                    margin: 0;
                    .col{
                        text-align: right;
                        padding:0;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        .nav-btn{
                            display: inline-block;
                            margin:0 10px;
                            padding:3px;
                            border-radius:4px;
                            // border: 1px solid #aaaaaa;
                            .b-icon.bi{
                                font-size: 30px;
                            }
                        }
                        .nav-log{
                            padding:0;
                        }
                        .nav-icon{
                            display: inline-block;
                            margin:2px 10px 0 0;
                            color: #aaaaaa;
                            .el-icon-user-solid{
                                font-size:24px;
                                margin-right:3px;
                                vertical-align: middle;
                            }
                            .el-icon-shopping-cart-full{
                                font-size:24px;
                                vertical-align: middle;
                            }
                        }
                    }
                    .lef{
                        text-align: left;
                        height: 60px;
                        line-height: 60px;
                    }
                    .centr{
                        width: 40%;
                        text-align: center;
                        .nav-log{
                            .lias{
                                display: block;
                                img{
                                    width: 50%;
                                }
                            }
                        }
                    }
                    .leg{
                        display: flex;
                        justify-content: flex-end;
                        text-align: right;
                        padding:0 10px 0 0;
                        .nav-icon{
                            cursor: pointer;
                            display: inline-block;
                            height:30px;
                            line-height:60px;
                            margin:0 5px 0;
                            vertical-align: middle;
                            
                            .caricon{
                                display: inline-block;
                                height:30px;
                                width: 30px;
                                background: url(~@/assets/images/icon_shopcar.png) no-repeat left top;
                                background-size:100% 100%;
                                vertical-align: middle;
                            }
                            .usericon{
                                display: inline-block;
                                height:30px;
                                width: 30px;
                                vertical-align: middle;
                                background: url(~@/assets/images/icon_user.png) no-repeat left top;
                                background-size:100% 100%;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
                
            }
        }
        .pc-nav-container{
            .row{
                img{
                    height:90px;
                    margin: 0 auto;
                }
                .nav-group{
                    padding:0 5px;
                    .nav-group-box{
                        font-size:12px;
                        padding: 5px ;
                        .stopicon{
                            font-size:12px;
                            height: 20px;
                            width:20px;
                            margin-right:5px;
                        }
                        .txtspe{
                            font-size:12px;
                            margin-left:5px;
                        }
                    }
                }
                .nav-group-txt{
                    font-size:12px;
                }
            }
        }
    }
    /deep/.modal-content{
        min-width: 300px;
    }
}
</style>